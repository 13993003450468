export class ParticipantRequest {
  constructor(participantRequest) {
    this.id = participantRequest.id;
    this.project_title = participantRequest.project_title;
    this.status = participantRequest.status;
    this.requested_participant_count = participantRequest.requested_participant_count;
    this.description = participantRequest.description;
    this.genders = participantRequest.genders;
    this.ages = participantRequest.ages.map(
      ageRequest =>
        `${ageRequest.min} - ${ageRequest.max !== null ? ageRequest.max : "+"}`,
    );
    this.email = participantRequest.email;
    this.team_name = participantRequest.team_name;
  }
}
