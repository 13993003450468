import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, TextField, Typography, Box, Paper, Container } from '@mui/material';
import {useTranslation} from "react-i18next";

export const ParticipantDetailsPage = () => {
  const {t} = useTranslation();
  const { id } = useParams();
  const [selectedRequest] = useState(`675675_10.10.2024 (ID: ${id})`);
  const [selectedGender, setSelectedGender] = useState('');
  const [selectedAge, setSelectedAge] = useState('');
  const [targetParticipants, setTargetParticipants] = useState('');
  const [activeSection, setActiveSection] = useState('send'); 

  useEffect(() => {
    setActiveSection('send');
  }, []);

  const handleSendParticipant = () => {
    setActiveSection('send');
  };

  const handleTakeOutParticipant = () => {
    setActiveSection('takeOut');
  };

  const handleSendSurvey = () => {
    console.log('Send Survey clicked');
    // Implement send survey logic here
  };

  const handleTakeOutSurvey = () => {
    console.log('Take out Survey clicked');
    // Implement take out survey logic here
  };

  return (
    <Container maxWidth="md" sx={{ padding: 2 }}>
      <Typography variant="h4" gutterBottom>
      {t("findParticipantsRequest.participantRequestManagment")}
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
        <Button
          variant={activeSection === 'send' ? "contained" : "outlined"}
          color="primary"
          onClick={handleSendParticipant}
          sx={{ flex: 1, marginRight: 1 }}
        >
          {t("findParticipantsRequest.sendParticipant")}
        </Button>
        <Button
          variant={activeSection === 'takeOut' ? "contained" : "outlined"}
          color="primary"
          onClick={handleTakeOutParticipant}
          sx={{ flex: 1, marginLeft: 1 }}
        >
          {t("findParticipantsRequest.takeOutParticipant")}
        </Button>
      </Box>

      {activeSection === 'send' && (
        <Paper elevation={3} sx={{ padding: 2, marginBottom: 2 }}>
          <Typography variant="subtitle1" gutterBottom>
          {t("sendParticipant.selectedRequest")}{selectedRequest}
          </Typography>
          <TextField
            fullWidth
            label={t("sendParticipant.surveyTopic")}
            margin="normal"
          />
          <TextField
            fullWidth
            label={t("sendParticipant.surveyDuration")}
            margin="normal"
          />
          <TextField
            fullWidth
            label={t("sendParticipant.surveyLink")}
            margin="normal"
          />
          <TextField
            fullWidth
            label={t("sendParticipant.targetNumberOfParticipants")}
            margin="normal"
          />
          <TextField
            fullWidth
            label={t("sendParticipant.participantGender")}
            margin="normal"
          />
          <TextField
            fullWidth
            label={t("sendParticipant.participantAge")}
            margin="normal"
          />
          <TextField
            fullWidth
            label={t("sendParticipant.rewardDefinition")}
            margin="normal"
          />
          <TextField
            fullWidth
            label={t("sendParticipant.paymentStatus")}
            margin="normal"
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSendSurvey}
            >
              {t("sendParticipant.sendSurvey")}
            </Button>
          </Box>
        </Paper>
      )}

      {activeSection === 'takeOut' && (
        <Paper elevation={3} sx={{ padding: 2, marginBottom: 2 }}>
          <Typography variant="subtitle1" gutterBottom>
          {t("sendParticipant.selectedRequest")} {selectedRequest}
          </Typography>
          <TextField
            fullWidth
            label={t("takeOutParticipant.selectedGender")}
            value={selectedGender}
            onChange={(e) => setSelectedGender(e.target.value)}
            margin="normal"
          />
          <TextField
            fullWidth
            label={t("takeOutParticipant.selectedAge")}
            value={selectedAge}
            onChange={(e) => setSelectedAge(e.target.value)}
            margin="normal"
          />
          <TextField
            fullWidth
            label={t("sendParticipant.targetNumberOfParticipants")}
            value={targetParticipants}
            onChange={(e) => setTargetParticipants(e.target.value)}
            margin="normal"
          />
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleTakeOutSurvey}
            >
              {t("takeOutParticipant.takeOutSurvey")}
            </Button>
          </Box>
        </Paper>
      )}
    </Container>
  );
};