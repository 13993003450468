import {createContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {newquestionService} from "src/new_services/survey/question";
import {newpageService} from "src/new_services/survey/page";
import {blockService} from "src/services/project/survey/block";
import {Survey, Block, Page, Question} from "src/entities/project/survey/survey";
import {projectService} from "src/services/project/project";
import {pageService} from "src/services/project/survey/page";
import {flowService} from "src/services/project/survey/flow";

export const SurveyBuilderContext = createContext({
  survey: null,
  blocks: null,
  pages: null,
  questions: null,

  surveyFlow: null,

  currentBlock: null,
  currentQuestion: null,
  getCurrentQuestion: _ => {},
  handleSetCurrentBlock: block => {},
  handleSetCurrentQuestion: question => {},

  // QUESTIONS
  listQuestions: (pageId, blockId) => {},
});

export const SurveyBuilderProvider = props => {
  const {children} = props;
  const {uuid} = useParams();
  const [currentBlock, setCurrentBlock] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [survey, setSurvey] = useState(null);
  const [blocks, setBlocks] = useState(null);
  const [pages, setPages] = useState(null);
  const [questions, setQuestions] = useState(null);
  const [surveyFlow, setSurveyFlow] = useState(null);

  useEffect(() => {
    projectService.retrieveProjectSurvey(uuid).then(retrievedSurvey => {
      setSurveyDetails(retrievedSurvey);
    });
  }, []);

  const setSurveyDetails = retrievedSurvey => {
    const tempBlocks = {};
    const tempPages = {};
    const tempQuestions = {};

    setSurvey(new Survey(retrievedSurvey));
    retrievedSurvey.blocks.forEach(block => {
      tempBlocks[block.id] = new Block(block);

      block.pages.forEach(page => {
        tempPages[page.id] = new Page(page, block.id);

        page.questions.forEach(question => {
          tempQuestions[question.id] = new Question(question, block.id, page.id);
        });
      });
    });
    setCurrentBlock(Object.values(tempBlocks)[0]);
    setCurrentQuestion(Object.values(tempQuestions)[0]);

    setBlocks(tempBlocks);
    setPages(tempPages);
    setQuestions(tempQuestions);
  };

  const handleUpdateSurveySettings = async data => {
    await projectService.updateProjectSurvey(survey.id, data).then(updatedSurvey => {
      setSurvey(new Survey(updatedSurvey));
    });
  };

  const handleSetCurrentQuestion = question => {
    setCurrentBlock(blocks[question.blockId]);
    setCurrentQuestion(question);
  };

  const handleSetCurrentBlock = block => {
    setCurrentQuestion(null);
    setCurrentBlock(block);
  };

  const getCurrentQuestion = _ => {
    return questions[currentQuestion.id];
  };

  const listBlockPages = _ => {
    return Object.values(pages).filter(p => p.blockId === currentBlock.id);
  };

  /* BLOCK */
  const handleCreateBlock = blockTitle => {
    const data = {
      project_id: uuid,
      title: blockTitle,
    };
    blockService.createBlock(data).then(block => {
      setBlocks(prevData => ({
        ...prevData,
        [block.id]: new Block(block),
      }));
    });
  };

  const handleUpdateBlock = async payload => {
    blockService.updateBlock(currentBlock.id, payload).then(block => {
      setBlocks(prevData => ({
        ...prevData,
        [currentBlock.id]: new Block(block),
      }));
    });
  };

  const handleRemoveBlock = blockId => {
    blockService.removeBlock(blockId).then(data => {
      setSurveyDetails(data);
    });
  };

  const handleCreateAllPageBreak = async _ => {
    const blockId = currentBlock.id;
    const tempPages = {...pages};
    const tempQuestions = {...questions};
    await blockService.createBlockPages(blockId).then(updatedBlock => {
      updatedBlock.pages.forEach(page => {
        tempPages[page.id] = new Page(page, updatedBlock.id);

        page.questions.forEach(question => {
          tempQuestions[question.id] = new Question(question, updatedBlock.id, page.id);
        });
      });
      if (updatedBlock.pages.length === 1) {
        const pagesToDelete = Object.values(pages).filter(
          page => page.blockId === blockId && page.id !== updatedBlock.pages[0].id,
        );
        pagesToDelete.forEach(page => {
          delete tempPages[page.id];
        });
      }

      setPages(tempPages);
      setQuestions(tempQuestions);
    });
  };

  const handleUpdateBlockQuestions = async data => {
    const blockId = currentBlock.id;
    const tempQuestions = {...questions};
    await blockService.updateBlockQuestions(blockId, data).then(updatedBlock => {
      updatedBlock.pages.forEach(page => {
        page.questions.forEach(question => {
          tempQuestions[question.id] = new Question(question, updatedBlock.id, page.id);
        });
      });
      setQuestions(tempQuestions);
    });
  };

  const handleCreatePageBreak = async questionId => {
    const data = {
      block_id: questions[questionId].blockId,
      page_id: questions[questionId].pageId,
      question_id: questionId,
    };
    const tempPages = {...pages};
    const tempQuestions = {...questions};
    await pageService.createPageBreak(data).then(updatedBlock => {
      updatedBlock.pages.forEach(page => {
        tempPages[page.id] = new Page(page, updatedBlock.id);

        page.questions.forEach(question => {
          tempQuestions[question.id] = new Question(question, updatedBlock.id, page.id);
        });
      });

      setPages(tempPages);
      setQuestions(tempQuestions);
    });
  };

  const handleRemovePageBreak = async pageId => {
    const tempPages = {...pages};
    const tempQuestions = {...questions};
    await pageService.removePageBreak(pageId).then(updatedBlock => {
      updatedBlock.pages.forEach(page => {
        tempPages[page.id] = new Page(page, updatedBlock.id);

        page.questions.forEach(question => {
          tempQuestions[question.id] = new Question(question, updatedBlock.id, page.id);
        });
      });
      delete tempPages[pageId];

      setPages(tempPages);
      setQuestions(tempQuestions);
    });
  };

  // QUESTION
  const handleCreateQuestion = payload => {
    const tempQuestions = {};
    newpageService.createQuestion(payload).then(pageQuestions => {
      pageQuestions.forEach(question => {
        tempQuestions[question.id] = new Question(
          question,
          payload.block_id,
          question.page_id,
        );
      });
      if (!payload.page_id) {
        const newPage = {id: pageQuestions[0].page_id, index: 0};
        setPages(prevData => ({
          ...prevData,
          [pageQuestions[0].page_id]: new Page(newPage, payload.block_id),
        }));
      }
      setQuestions(prevData => ({
        ...prevData,
        ...tempQuestions,
      }));
    });
  };

  const listQuestions = (pageId, blockId) => {
    if (pageId) {
      return Object.values(questions)
        .filter(question => question.pageId === pageId)
        .sort((a, b) => a.index - b.index);
    } else if (blockId) {
      return Object.values(questions).filter(question => question.blockId === blockId);
    }
    return Object.values(questions).sort((a, b) => a.index - b.index);
  };

  const handleUpdateQuestion = (questionId, payload) => {
    newquestionService.updateQuestion(questionId, payload).then(question => {
      setQuestions(prevData => ({
        ...prevData,
        [questionId]: {
          ...questions[questionId],
          ...question,
        },
      }));
    });
  };

  const handleCreateDisplayLogicQuestion = (questionId, payload) => {
    newquestionService.createQuestionDisplayLogic(questionId, payload).then(question => {
      setQuestions(prevData => ({
        ...prevData,
        [questionId]: {
          ...questions[questionId],
          ...question,
        },
      }));
    });
  };

  const handleUpdateQuestionType = (questionId, newQuestionType) => {
    const payload = {
      new_type: newQuestionType,
    };
    const blockId = questions[questionId].blockId;
    const pageId = questions[questionId].pageId;
    newquestionService.updateQuestionType(questionId, payload).then(question => {
      const newQuestions = {
        ...questions,
        [question.id]: {blockId: blockId, pageId: pageId, ...question},
      };
      delete newQuestions[questionId];
      setQuestions(newQuestions);
      setCurrentQuestion(question);
    });
  };

  const handleCopyQuestion = questionId => {
    const tempQuestions = {};
    const pageId = questions[questionId].pageId;
    const blockId = questions[questionId].blockId;
    newpageService.copyQuestion(pageId, questionId).then(pageQuestions => {
      pageQuestions.forEach(question => {
        tempQuestions[question.id] = {
          pageId: pageId,
          blockId: blockId,
          ...question,
        };
      });
      setQuestions(prevData => ({
        ...prevData,
        ...tempQuestions,
      }));
    });
  };

  const handleRemoveQuestion = questionId => {
    const pageId = questions[questionId].pageId;

    const tempPages = {...pages};
    const tempQuestions = {...questions};
    newpageService.removeQuestion(pageId, questionId).then(updatedBlock => {
      updatedBlock.pages.forEach(page => {
        tempPages[page.id] = new Page(page, updatedBlock.id);

        page.questions.forEach(question => {
          tempQuestions[question.id] = new Question(question, updatedBlock.id, page.id);
        });
      });

      if (!updatedBlock.pages.find(page => page.id === pageId)) {
        delete tempPages[pageId];
      }
      delete tempQuestions[questionId];
      setCurrentQuestion(null);

      setPages(tempPages);
      setQuestions(tempQuestions);
    });
  };

  // MULTIPLE_CHOICE QUESTION
  const handleCreateMultipleChoiceQuestionOptions = questionId => {
    newquestionService.createMultipleChoiceQuestionOptions(questionId).then(question => {
      setQuestions(prevData => ({
        ...prevData,
        [questionId]: {
          ...questions[questionId],
          options: question.options,
        },
      }));
    });
  };

  const handleUpdateMultipleChoiceQuestionOptions = (questionId, options) => {
    newquestionService
      .updateMultipleChoiceQuestionOptions(questionId, options)
      .then(question => {
        setQuestions(prevData => ({
          ...prevData,
          [questionId]: {
            ...questions[questionId],
            options: question.options,
          },
        }));
      });
  };

  const handleDeleteMultipleChoiceQuestionOptions = (questionId, optionId) => {
    newquestionService
      .deleteMultipleChoiceQuestionOptions(questionId, optionId)
      .then(question => {
        setQuestions(prevData => ({
          ...prevData,
          [questionId]: {
            ...questions[questionId],
            options: question.options,
            display_logics: question.display_logics, // FIXME: update actual question display_logics
            skip_logics: question.skip_logics,
          },
        }));
      });
  };

  const handleCreateQuestionSkipLogic = (questionId, payload) => {
    newquestionService.createQuestionSkipLogic(questionId, payload).then(question => {
      setQuestions(prevData => ({
        ...prevData,
        [questionId]: {
          ...questions[questionId],
          skip_logics: question.skip_logics,
        },
      }));
    });
  };

  const handleRemoveQuestionSkipLogic = (questionId, skipLogicId) => {
    newquestionService.deleteQuestionSkipLogic(questionId, skipLogicId).then(question => {
      setQuestions(prevData => ({
        ...prevData,
        [questionId]: {
          ...questions[questionId],
          skip_logics: question.skip_logics,
        },
      }));
    });
  };

  const handleDeleteMultipleChoiceQuestionCarryChoiceOptions = questionId => {
    newquestionService
      .deleteMultipleChoiceQuestionCarryOptions(questionId)
      .then(question => {
        setQuestions(prevData => ({
          ...prevData,
          [questionId]: {
            ...questions[questionId],
            options: question.options,
          },
        }));
      });
  };

  // FLOW
  const handleFetchSurveyFlow = () => {
    flowService.retrieveSurveyFlow(survey.id).then(surveyFlow => {
      setSurveyFlow(surveyFlow);
    });
  };

  const handleCreateFlowElement = (flowId, data) => {
    flowService.createFlowElement(survey.id, flowId, data).then(surveyFlow => {
      setSurveyFlow(surveyFlow);
    });
  };

  const handleDragAndDropFlowElements = data => {
    flowService.dragAndDropElement(survey.id, data).then(surveyFlow => {
      setSurveyFlow(surveyFlow);
    });
  };

  const handleDeleteFlowElement = elementId => {
    flowService.deleteFlowElement(survey.id, elementId).then(surveyFlow => {
      setSurveyFlow(surveyFlow);
    });
  };

  const handleUpdateBranchCondition = (elementId, data) => {
    flowService.updateBranchCondition(survey.id, elementId, data).then(surveyFlow => {
      setSurveyFlow(surveyFlow);
    });
  };

  return (
    <SurveyBuilderContext.Provider
      value={{
        survey,
        blocks,
        pages,

        surveyFlow,

        currentBlock,
        currentQuestion,
        getCurrentQuestion,
        listBlockPages,
        handleSetCurrentBlock,
        handleSetCurrentQuestion,

        // SURVEY
        handleUpdateSurveySettings,
        handleUpdateBlockQuestions,

        // BLOCK
        handleCreateBlock,
        handleUpdateBlock,
        handleRemoveBlock,
        handleCreatePageBreak,
        handleCreateAllPageBreak,
        handleRemovePageBreak,

        // QUESTION
        listQuestions,
        handleCreateQuestion,
        handleUpdateQuestion,
        handleUpdateQuestionType,
        handleCopyQuestion,
        handleRemoveQuestion,
        handleCreateDisplayLogicQuestion,
        handleCreateMultipleChoiceQuestionOptions,
        handleDeleteMultipleChoiceQuestionCarryChoiceOptions,
        handleUpdateMultipleChoiceQuestionOptions,
        handleDeleteMultipleChoiceQuestionOptions,
        handleCreateQuestionSkipLogic,
        handleRemoveQuestionSkipLogic,

        // FLOW
        handleFetchSurveyFlow,
        handleCreateFlowElement,
        handleDeleteFlowElement,
        handleUpdateBranchCondition,
        handleDragAndDropFlowElements,
      }}
    >
      {children}
    </SurveyBuilderContext.Provider>
  );
};

export const SurveyBuilderConsumer = SurveyBuilderContext.Consumer;
