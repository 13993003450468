export const trTranslations = {
  mySurveys: "Anketlerim",
  newSurvey: "Yeni Anket",
  AddRandomizer: "Rastgeleleştir",
  question: "Soru",
  newSurveys: {
    createNewSurveyTitle: "Yeni anket oluştur",
    createNewSurveyDescription: "Sıfırdan veya bir excel dosyasından başla",
    newSurveyFromScratch: "Sıfırdan yeni anket oluştur",
    newSurveyFromExcel: "Excelden yeni anket oluştur",
    newSurveyFromScratchDescription: "Sıfırdan başla",
    uploadFromExcel: "Excelden Yükle",
    selectFromPopularTemplates: "En popüler şablonları seç",
    demographicPoll: "Demografik Anket",
    customerFeedback: "Müşteri Geribildirimi",
    customizeDraft: "Taslağı Özelleştir",
    employeeFeedback: "Çalışan Geribildirimi",
    startSurvey: "Başla",
  },

  selectTheSurveyYouWantToDistribute: "Dağıtmak İstediğiniz Anketi Seçin",
  selectParticipantDemographics: "Katılımcı Demografisini Seçin",
  sendToAnAgentToGetAQuote: "Teklifi almak için bir acenteye gönderin",
  findParticipants: {
    selectTheSurvey: "Anket Seç",
    numberofParticipants: "Katılımcı Sayısı",
    selectGender: "Cinsiyet Seç",
    selectAgeGroups: "Yaş Grubu Seç",
    projectDetails: "Proje Detayları",
  },
  findparticipants: "Katılımcıları Bul",

  findParticipantsRequest: {
    findParticipantsRequest: "Katılımcı Bulma İstekleri",

    requestedStatus: "İstek Durumu",
    requestedDate: "İstek Tarihi",
    surveyName: "Anket Adı",
    surveyDescription: "Anket Açıklaması",
    requestedGender: "İstenilen Cinsiyet",
    requestedAge: "İstenilen Yaş",
    numberOfParticipants: "Katılımcı Sayısı",
    participantRequestManagment: "Katılımcı Talep Yönetimi",
    sendParticipant: "Katılımcı Gönder",
    takeOutParticipant: "Katılımcı Çıkar",
  },
  sendParticipant: {
    selectedRequest: "Seçilen Talep: ",
    surveyTopic: "Anket Konusu",
    surveyDuration: "Anket Süresi",
    surveyLink: "Anket Linki",
    targetNumberOfParticipants: "Hedef Katılımcı Sayısı",
    participantGender: "Katılımcı Cinsiyeti",
    participantAge: "Katılımcı Yaşı",
    rewardDefinition: "Ödül Tanımı",
    paymentStatus: "Ödeme Durumu",
    sendSurvey: "Anketi Gönder",
  },

  takeOutParticipant: {
    selectedGender: "Seçilen Cinsiyet",
    selectedAge: "Seçilen Yaş",
    takeOutSurvey: "Anketten Çıkar",
  },

  submittedRequests: {
    submittedRequests: "Gönderilen İstekler",
    survey: "Anket",
    participants: "Katılımcılar",
    gender: "Cinsiyet",
    age: "Yaş",
    projectDetails: "Proje Detayları",
    status: "Durum",
    noSubmittedRequestsYet: "Henüz gönderilen istek yok.",
    submit: "Gönder",
  },

  settingcategory: "Kategori Ayarları",
  none: "Seçilmedi",
  male: "Erkek",
  female: "Kadın",
  other: "Diğer",
  all: "Hepsi",
  editInfo: {
    editInfo: "Bilgileri Düzenle",
    mail: "Mail",
    phoneNumber: "Telefon No (0532 000 00 00)",
    gender: "Cinsiyet",
    birthday: "Doğum Günü",
    country: "Ülke",
    province: "Şehir",
    editAccountInformation: "Hesap Bilgilerini Düzenle",
    saveAccountInformation: "Hesap Bilgilerini Kaydet",
    changePassword: "Şifre Değiştir",
    cancel: "Geri",
    delete: "Sil",
    changeCommunicationPreferences: "İletişim Tercihlerini Değiştir",
    deleteAccountConfirmation: "Hesabınızı silmek istediğinizden emin misiniz?",
    deleteAccountConfirmationDescription:
      "Hesabınızın silinmesi geri alınamaz. Tüm verileriniz kalıcı olarak silinecek. Devam etmek istediğinizden emin misiniz?",
    deleteAccount: "Hesabı Sil",
    changePasswordConfirmation: "Şifrenizi değiştirmek istediğinizden emin misiniz?",
    changePasswordConfirmationDescription:
      "Şifrenizi değiştirmek tekrar giriş yapmanızı gerektirecektir. Devam etmek istediğinizden emin misiniz?",
  },

  excellToSurvey: "Excel'den Ankete",

  tableHeaders: {
    title: "Başlık",
    isPublished: "Aktiflik Durumu",
    questions: "Sorular",
    responses: "Yanıtlar",
    completion: "Tamamlama",
    created: "Oluşturulma Tarihi",
    updated: "Güncellenme Tarihi",
    actions: "Eylemler",
  },
  prev: "Önceki Buton",
  next: "Sonraki Buton",
  noDataFound: "Veri bulunamadı",
  publish: "Yayınla",
  published: "Yayında",
  draft: "Taslak",
  settings: {
    surveyTitle: "Anket Başlığı",
    open: "Açık",
    close: "Kapalı",
  },
  block: {
    enterBlockName: "Blok Adı Girin",
  },
  buttons: {
    downloadDraftExcel: "Taslak Excel Dosyasını İndir",
    UploadDraftExcel: "Düzenlenmiş Excel Dosyası",
    yourDraftExcel: "Excel Dosyanız",
  },
  step: "Adım",
  hasBeenUploaded: "Yüklendi",
  downloadExcelFormat: "Excel Formatını İndir",
  writeToExcelFormat: "Sorularınızı excel dosyasına yazın veya yapıştırın",
  uploadQuestionsFromExcel: "Anket sorularınızı Excel'den doğrudan platforma yükleyin",
  downloadDraftExcelDirective:
    "Kendi sorularınızı kopyalayıp yapıştırmak için taslak excel'i indirebilirsiniz. Soru ve blok ayarlarını bu excelden yönetebilirsiniz, örneğin gerekli, randomizasyon, çoklu cevap izni gibi.",
  defineEditedExcelFile:
    "Lütfen anket adınızı tanımlayın ve düzenlenmiş excel dosyanızı yükleyin.",
  importExcelButtonLabel: "Excel Dosyasından İçe Aktar",
  importExcelDialogTitle: "Excel Dosyası İçe Aktar",
  importExcelNameLabel: "İsim",
  importFileButtonLabel: "Dosyayı İçe Aktar",
  createSurveyButtonLabel: "Oluştur",
  createSurveyDialogTitle: "Anket Oluştur",
  nameInputLabel: "Ad",
  createButtonLabel: "Oluştur",
  cancelButtonLabel: "İptal",
  copyLink: "Bağlantıyı Kopyala",
  copySurvey: "Anketi Kopyala",
  delete: "Sil",
  deleteSurveyTitle: "Anketi Sil",
  DeleteSurveyConfirmation:
    "{{surveyTitle}} anketi kalıcı olarak silinecek. Emin misiniz?",

  cancel: "İptal",
  apply: "Uygula",

  sections: {
    survey: "Anket",
    preview: "Önizleme",
    distributions: "Dağıtımlar",
    results: "Sonuçlar",
    analyticsDashboard: "Analitik Grafik Ekranı",
  },
  theme: {
    defaultTheme: "Varsayılan Tema",
    brandDarkTheme: "Koyu Marka Teması",
    brandLightTheme: "Açık Marka Teması",
    grayTheme: "Gri Tema",
    darkUnicaTheme: "Koyu Unica Teması",
    sandSignikaTheme: "Kum Signika Teması",
    oceanBreezeTheme: "Okyanus Esintisi Teması",
    sunsetGlowTheme: "Gün Batımı Parıltısı Teması",
  },
  basicSettings: {
    title: "Temel Ayarlar",
    language: "Dil",
    languageDescription:
      "Anketinizi yazdığınız dili seçmenizi öneririz. Yanıt veri de bu dilde görüntülenecektir.",
    previousButton: "Önceki Buton",
    previousButtonDescription: "Önceki butonu etkinleştir/devre dışı bırak.",
    ipBlock: "IP Engelleme",
    ipBlockDescription: "Aynı IP'den birden fazla yanıtı engelle.",
  },
  writeSomething: "Bir şeyler yazın",
  unavailableSurveyMessage: "Kullanılamayan Anket Mesajı",
  buttonLabels: {
    update: "Güncelle",
    reset: "Sıfırla",
  },
  blockSettings: {
    block: "Blok",
    editBlock: "Blok Düzenle",
    displayLogicWarning:
      "Aynı blok içinde görüntüleme mantığı ve rastgeleleştirme kullanmaya çalışılıyor. Bu kombinasyon beklenildiği gibi çalışmayabilir.",
    randomizeQuestions: "Soruları Rastgeleleştir",
    addPageBreak: "Tüm Sorulara Sayfa Sonu Ekle",
    makeAllRequired: "Tüm Soruları Zorunlu Yap",
    allowMultipleAnswers: "Birden Çok Cevaba İzin Ver",
    otherButton: "Diğer Buton",
    carryChoices: "Taşıma Seçenekleri",
    skipLogic: "Şartlı Soru Atlatma",
    selectAnOption: "Bir Opsiyon Seç",
    selectOperand: "Operand Seçin",

    noMultipleQuestions: "Çok Sayıda Soru Yok",
    choices: "Seçenekler",

    selectAQuestion: "Bir Soru Seç",
    skipTo: "Atla",
    endOfSurvey: "Anket Sonu",
    endOfBlock: "Blok Sonu",
  },

  flow: {
    flowList: "Akış Listesi",
    whatDoYouWantToAdd: "Ne Eklemek İstiyorsun?",
    moveButton: "Taşı",
  },

  results: {
    users: "Kullanıcılar",
    completed: "Tamamlandı",
    startDate: "Başlama Tarihi",
    endDate: "Bitiş Tarihi",
    duration: "Süre",
    status: "Durum",
  },

  chartType: {
    columnChart: "Sütun Grafik",
    barChart: "Çubuk Grafik",
    pieChart: "Pasta Grafik",
    lineChart: "Çizgi Grafik",
    areaChart: "Alan Grafik",
    scatterChart: "Dağılım Grafik",
    splineChart: "Spline Grafik",
    bubbleChart: "Balon Grafik",
    polarChart: "Kutup Grafik",
    radarChart: "Radar Grafik",
  },
  editor: {
    endPageLabel: "Son Sayfa",
    createBlockTooltip: "Soru Bloğu Oluştur",
    editBlockTooltip: "Soru Bloğunu Düzenle",
    deleteBlockTooltip: "Soru Bloğunu Sil",
    blockDeletionConfirmation: "bloğu kalıcı olarak silinecek. Emin misin?",
  },
  analyticsDashboard: {
    firstQuestionForChart: "Grafik İçin İlk Soruyu Seçin",
    addSecondQuestionforChart: "Grafik için İkinci Soruyu Ekleyin",
    selectSecondQuestionForchart: "Grafik için İkinci Soruyu Seçin",
    selectAQuestion: "Bir soru seçin",
    chartTitle: "Grafik Başlığı",
    unitType: "Birim Türü",
    chartType: "Grafik Türü",
    highchartsWithMUI: "MUI ile Highcharts",
  },

  downloadResults: "Sonuçları İndir",
  resetSurvey: "Anketi Sıfırla",
  questionLabel: "Soru Etiketi",
  duplicate: "Çoğalt",
  remove: "Sil",
  shuffleQuestion: "Seçenekleri Karıştır",
  deleteQuestion: "Soruyu Sil",
  ignoreRequire: "Zorunlu Cevaplamayı Yoksay",
  ignorePreviewData: "Önizleme Verilerini Yoksay",
  addOption: "Seçenek Ekle",

  label: "Varsayılan Soru Etiketi",
  choice1: "Seçenek 1",
  choice2: "Seçenek 2",
  choice3: "Seçenek 3",
  defaultQuestion: "Varsayılan Soru Başlığı",
  save: "Kaydet",
  reset: "Sıfırla",
  isEqual: "Eşit",
  isNotEqual: "Eşit değil",
  isdisplayed: "Görünür",
  isNotdisplayed: "Görünür Değil",
  isEmpty: "Boş",
  isNotEmpty: "Boş Değil",
  isSelected: "Seçildi",
  isNotSelected: "Seçilmedi",

  allOptions: "Tüm Seçenekler",
  selectedOptions: "Seçili Seçenekler",
  unselectedOptions: "Seçilmemiş Seçenekler",

  key: "Anahtar",
  value: "Değer",

  EditQuestion: "Soruyu Düzenle",
  QuestionType: "Soru Tipi",
  logicType: "Mantık Tipi",
  embeddedData: "Gömülü Veri",

  Text: "Metin",
  Settings: "Ayarlar",
  Behaviours: "Davranışlar",
  MultipleChoice: "Çoktan Seçmeli",
  ShortText: "Kısa Metin",
  TextGraphic: "Metin / Grafik",
  SurveyQuestion: "Anket Sorusu",
  EmbeddedDataField: "Gömülü Veri Alanı",
  EditQuestionTextDialogTitle: "Soru Metnini Düzenle",
  PlaceholderWriteSomething: "Bir şeyler yazın",
  CancelButton: "İptal",

  SaveButton: "Kaydet",
  RequiredLabel: "Gerekli",
  ContentTypeLabel: "İçerik Türü",
  TextLabel: "Metin",
  NumberLabel: "Sayı",
  EmailAddressLabel: "E-posta Adresi",
  RequiredQuestionError: "Bu soru zorunludur.",
  textInputTypeError: "Metin girişi geçersiz.",
  integerInputTypeError: "Sayı girişi geçersiz.",
  phoneNumberTypeError:
    "Lütfen 0532 052 52 82 formatında geçerli bir telefon numarası giriniz.",
  numberTypeError: "Lütfen geçerli bir sayı giriniz.",
  emailInputTypeError: "E-posta girişi geçersiz.",
  DisplayLogic: "Görüntüleme Mantığı",
  DownloadQRCode: "QR Kodunu İndir",
  CopyLink: "Bağlantıyı Kopyala",
  AddElement: "Öğe Ekle",
  AddCondition: "Koşul Ekle",
  EditCondition: "Koşulu Düzenle",

  CloseWindow: "Pencereyi Kapat",
  Link: "Bağlantı",
  QRCode: "QR Kodu",
  CreateQuestion: "Soru Oluştur",
  AddPageBreak: "+ Sayfa Sonu Ekle",
  LogoutButton: "Çıkış Yap",
  AddBlock: "Blok Ekle",
  AddBranch: "Dal Ekle",
  successfullyUpdated: "Başarıyla Güncellendi",

  addBelow: "Aşağı Ekle",

  surveyIsNotPublished: "Anket yayınlanmadı",

  builder: "Oluşturucu",
  surveyFlow: "Anket akışı",
  surveyOptions: "Anket seçenekleri",
  design: "Tasarım",

  auth: {
    login: "Giriş yap",
    register: "Kayıt ol",
    emailIsRequired: "Lütfen e-posta adresinizi giriniz",
    passwordIsRequired: "Lütfen şifrenizi giriniz",
    passwordMustBeValid: "Geçerli bir e-posta adresi giriniz",
    alreadyhaveaccount: "Zaten Hesabın Varsa",
    emailOrPassIncorrect: "E-posta veya şifre hatalı, lütfen tekrar deneyin.",
    resetPassword: {
      title: "Şifre Yenile",
      textLabel: "Email Adresi veya Telefon numarası",
      buttonLabel: "Aktivasyon linkini gönder",
      invalidInputMessage:
        "Geçerli bir email veya telefon numarası (05329993377) girilmelidir.",
      confirmationMessage:
        "Eğer bilgileriniz geçerliyse aktivasyon için mail/SMS gönderilecek. Mail adresinizi veya telefonunuzu kontrol edebilirsiniz. Sorularınız için: katilimcihavuzu@galataanket.com",
    },
    emailAddress: "E-posta Adresi",
    password: "Şifre",
    continue: "Devam et",
    sendnewpassword: "Send a new password",
    forgotPassword: "Şifrenizi mi unuttunuz?",
    noAccount: "Hesabınız yok mu?",
  },
  designTab: {
    logo: "Logo",
    general: "Genel",
    background: "Arka Plan",
    style: "Stil",
    motion: "Hareket",
    similars: {
      left: "Sol",
      center: "Orta",
      right: "Sağ",
      top: "Üst",
      bottom: "Alt",
    },
    logoStyles: {
      logoImage: "Logo Görüntüsü",
      change: "Değiştir",
      placement: "Yerleştirme",
      verticalPlacement: "Dikey Yerleştirme",
      maxHeight: "Maksimum Yükseklik",
      maxWidth: "Maksimum Genişlik",
    },
    generalStyles: {
      nextButtonText: "Sonraki Buton Metni",
      previousButtonText: "Önceki Buton Metni",
      progressBar: "İlerleme Çubuğu",
      none: "Hiçbiri",
      withText: "Metinli",
      withoutText: "Metinsiz",
      progressBarPlacement: "İlerleme Çubuğu Yerleşimi",
      progressBarVerticalPlacement: "İlerleme Çubuğu Dikey Yerleşimi",
    },
    styleStyles: {
      primaryColor: "Ana Renk",
      secondaryColor: "İkincil Renk",
      font: "Yazı Tipi",
      questionFontSize: "Soru Yazı Boyutu (px)",
      answersFontSize: "Cevaplar Yazı Boyutu (px)",
    },
    backgroundStyles: {
      backgroundColor: "Arka Plan Rengi",
    },
    motionStyles: {
      animationType: "Animasyon Türü",
      slide: "Kaydır",
      fade: "Solma",
      grow: "Büyüt",
      zoom: "Yakınlaştır",
      collapse: "Daralt",
      slideAnimationDirection: "Kaydırma Animasyonu Yönü",
    },
    previewSide: {
      mySurvey: "Anketim",
      sampleSurvey: "Örnek Anket",
      viewDesktop: "Masaüstü Görünümü",
      viewMobile: "Mobil Görünümü",
      selectSurvey: "Anket Seç",
    },
  },
};
