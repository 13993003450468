import axiosInstance from "src/services/axiosInstance";

class ParticipantRequestService {
  async listParticipantRequests(page) {
    return await axiosInstance
      .get(`/super/participant-requests`, {
        params: {page: page},
      })
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }
}

export const superParticipantRequestService = new ParticipantRequestService();
