import {useTranslation} from "react-i18next";
import SvgIcon from "@mui/material/SvgIcon";
import {paths} from "src/pages/paths";
import BuildingIcon from "src/icons/building";
import BallotIcon from "@mui/icons-material/Ballot"; // Import the AddToPhotos icon
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos"; // Import the AddToPhotos icon
import FindParticiIcon from "@mui/icons-material/GroupAdd"; // Import the AddToPhotos icon
import SettingsIcon from "@mui/icons-material/Settings"; // Import the AddToPhotos icon
import UploadFileIcon from "@mui/icons-material/UploadFile";

export const useSections = user => {
  const {t} = useTranslation();

  const surveys = {
    title: t("mySurveys"),
    path: paths.dashboard.surveys.list,
    icon: (
      <SvgIcon fontSize="small">
        <BallotIcon />
      </SvgIcon>
    ),
  };

  const unsubscribedUsers = {
    title: t("unsubscribedUsers"),
    path: "/dashboard/unsubscribed-users",
    icon: (
      <SvgIcon fontSize="small">
        <BallotIcon />
      </SvgIcon>
    ),
  };

  const newsurvey = {
    title: t("newSurvey"),
    path: "/dashboard/newsurvey",
    icon: (
      <SvgIcon fontSize="small">
        <AddToPhotosIcon />
      </SvgIcon>
    ),
  };

  // const surveycagetories = {
  //   title: t("surveycagetories"),
  //   path: paths.dashboard.surveys.list,
  //   icon: (
  //     <SvgIcon fontSize="small">
  //       <DatasetIcon />
  //     </SvgIcon>
  //   ),
  //   items: [
  //     {
  //       title: t("demograhicscategory"),
  //       path: paths.dashboard.surveys.deneme,
  //       icon: (
  //         <SvgIcon fontSize="small">
  //           <HomeSmileIcon />
  //         </SvgIcon>
  //       ),
  //     },
  //     {
  //       title: t("costumerexpcategory"),
  //       path: paths.dashboard.surveys.deneme,
  //       icon: (
  //         <SvgIcon fontSize="small">
  //           <HomeSmileIcon />
  //         </SvgIcon>
  //       ),
  //     },
  //     {
  //       title: t("employeecategory"),
  //       path: paths.dashboard.surveys.deneme,
  //       icon: (
  //         <SvgIcon fontSize="small">
  //           <HomeSmileIcon />
  //         </SvgIcon>
  //       ),
  //     },
  //   ],
  // };
  const findparticipants = {
    title: t("findparticipants"),
    path: "/dashboard/findparticipants",
    icon: (
      <SvgIcon fontSize="small">
        <FindParticiIcon />
      </SvgIcon>
    ),
  };

  const settingscategory = {
    title: t("settingcategory"),
    path: "/dashboard/settingcategory",
    icon: (
      <SvgIcon fontSize="small">
        <SettingsIcon />
      </SvgIcon>
    ),
  };
  const findParticipantsRequest = {
    title: t("findParticipantsRequest.findParticipantsRequest"),
    path: paths.dashboard.participantRequests.list,
    icon: (
      <SvgIcon fontSize="small">
        <FindParticiIcon />
      </SvgIcon>
    ),
  };

  const excellToSurvey = {
    title: t("excellToSurvey"),
    path: "/dashboard/excelltosurvey",
    icon: (
      <SvgIcon fontSize="small">
        <UploadFileIcon />
      </SvgIcon>
    ),
  };

  const teams = {
    title: "Teams",
    path: paths.dashboard.teams.list,
    icon: (
      <SvgIcon fontSize="small">
        <BuildingIcon />
      </SvgIcon>
    ),
  };

  if (user?.is_superuser) {
    return [surveys, teams, unsubscribedUsers];
  }
  return [
    surveys,
    newsurvey,
    //surveycagetories,
    findparticipants,
    settingscategory,
    findParticipantsRequest,
    excellToSurvey,
  ];
};
